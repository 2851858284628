import React, { useEffect, useState } from 'react';
import { User, getAuth } from 'firebase/auth';
import { Box, Button, Text, Input, Select, VStack, Spinner, FormLabel, Heading, useToast, Alert, AlertIcon } from '@chakra-ui/react';
import { checkCompanyData, checkApiKey, saveCompanyData } from '../utils/firebase.utils';

interface ResumeProps {
  user: User | null;
}

const Resume: React.FC<ResumeProps> = ({ user }) => {
  const [domainStatus, setDomainStatus] = useState<'checking' | 'invalid' | 'valid'>('checking');
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companyExists, setCompanyExists] = useState(false);
  const [apiKeyExists, setApiKeyExists] = useState(false);
  const toast = useToast();
  
  useEffect(() => {
    const validateEmailDomain = async () => {
      if (user?.email) {
        const domain = user.email.split('@')[1]?.toLowerCase();
        const personalDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com']; 
        
        if (personalDomains.includes(domain)) {
          setDomainStatus('invalid');
        } else {
          const { companyExists, companyName } = await checkCompanyData(domain);
          setCompanyExists(companyExists);
          setCompanyName(companyName || '');
        
          if (companyExists) {
            const apiKeyData = await checkApiKey(domain);
            setApiKeyExists(apiKeyData.apiKeyExists);
          }
          
          setDomainStatus('valid');
        }
      }
      setLoading(false);
    };

    validateEmailDomain();
  }, [user]);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const domain = user?.email!.split('@')[1];
    const fullName = (document.getElementById('fullName') as HTMLInputElement).value;
    const selectedTeamSize = (document.getElementById('teamSize') as HTMLSelectElement).value;
    const referralSource = (document.getElementById('referralSource') as HTMLInputElement).value;

    try {
      await saveCompanyData(domain, { fullName, companyName, teamSize: selectedTeamSize, referralSource });
      toast({ title: "Data saved successfully", status: "success", duration: 3000, isClosable: true });

      // Recheck for apiKey after saving
      const apiKeyData = await checkApiKey(domain);
      setApiKeyExists(apiKeyData.apiKeyExists);
    } catch (error) {
      toast({ title: "Error saving data", description: (error as Error).message, status: "error", duration: 3000, isClosable: true });
    }
  };

  if (domainStatus === 'checking' || loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="xl" />
        <Text mt={3}>Loading your data...</Text>
      </Box>
    );
  }

  if (domainStatus === 'invalid') {
    return (
      <Box textAlign="center" mt={10} mx="auto" maxW="lg" bg="gray.50" p={6} >
        <Alert status="error" borderRadius="md" mb={4}>
          <AlertIcon />
          <Text fontSize="lg" color="red.700">You cannot register using a personal email. Please use your company email.</Text>
        </Alert>
        <Button onClick={() => getAuth().signOut()} colorScheme="teal" mt={4}>
          Back to Login
        </Button>
      </Box>
    );
  }

  if (!companyExists) {
    return (
      <Box textAlign="center" p={8} mx="auto" maxW="lg" bg="gray.50" borderRadius="md" boxShadow="lg">
        <Heading as="h1" size="lg" mb={4}>Welcome to ObjectionGuru!</Heading>
        <Text fontSize="md" color="gray.600" mb={8}>Please fill in your company details to get started.</Text>
        <VStack spacing={6} as="form" onSubmit={handleFormSubmit}>
          <FormLabel htmlFor="fullName">Full Name</FormLabel>
          <Input id="fullName" placeholder="Full Name" defaultValue={user?.displayName || ''} />
          <FormLabel htmlFor="companyName">Company Name</FormLabel>
          <Input id="companyName" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          <FormLabel htmlFor="teamSize">Team Size</FormLabel>
          <Select id="teamSize" placeholder="Select your team size">
            <option value="1-5">1-5</option>
            <option value="5-10">5-10</option>
            <option value="10-30">10-30</option>
            <option value="30-80">30-80</option>
            <option value="81+">81+</option>
          </Select>
          <FormLabel htmlFor="referralSource">How did you hear about us?</FormLabel>
          <Input id="referralSource" placeholder="Mention source" />
          <Button colorScheme="teal" type="submit">Submit</Button>
        </VStack>
      </Box>
    );
  }

  if (!apiKeyExists) {
    return (
      <Box textAlign="center" mt={10} mx="auto" maxW="lg" bg="gray.50" p={6} borderRadius="md" boxShadow="md">
        <Alert status="info" borderRadius="md" mb={4}>
          <AlertIcon />
          <Heading size="md" mb={2}>Account Setup in Progress</Heading>
          <Text color="gray.700">We are currently setting up your account with your company’s information. Your customized training agents are being generated. We will contact you as soon as it’s ready for testing.</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Box textAlign="center" mt={10}>
      <Text>Welcome to ObjectionGuru! Explore your role-plays below.</Text>
      {/* Render the RolePlaysList component here */}
    </Box>
  );
};

export default Resume;