import React from 'react';
import { Box, VStack, Heading, Text, SimpleGrid, Icon, Progress, Button, HStack } from '@chakra-ui/react';
import { FaChartLine, FaHandshake, FaBullhorn, FaComments, FaSmile, FaLanguage, FaEye } from 'react-icons/fa';

const CallSkillsScoreCard: React.FC = () => {
  // Example scores for demonstration
  const averageScore = 85;
  const skills = [
    { category: 'Sales Skills', skills: [
      { name: 'Discovery', score: 80, icon: FaChartLine },
      { name: 'Objection Management', score: 75, icon: FaHandshake },
      { name: 'Call to Action', score: 90, icon: FaBullhorn },
    ]},
    { category: 'Communication Skills', skills: [
      { name: 'Empathy', score: 85, icon: FaSmile },
      { name: 'Clear Language', score: 88, icon: FaLanguage },
      { name: 'Transparency', score: 82, icon: FaEye },
    ]}
  ];

  return (
    <Box bg="gray.100" p={4} borderRadius="md" boxShadow="sm" maxW="400px" mx="auto">
      <VStack spacing={4} align="stretch" bg="white" p={4} borderRadius="md" boxShadow="md">
        <Heading size="md" color="gray.800" textAlign="center">
          Call Skills Score
        </Heading>
        <Box textAlign="center">
          <Text fontSize="lg" fontWeight="bold" color="teal.600">
            Average Score: {averageScore}%
          </Text>
          <Progress value={averageScore} size="sm" colorScheme="teal" mt={1} />
        </Box>
        {skills.map((category, index) => (
          <Box key={index}>
            <Heading size="sm" color="gray.700" mt={2} mb={1}>
              {category.category}
            </Heading>
            <SimpleGrid columns={1} spacing={2}>
              {category.skills.map((skill, idx) => (
                <HStack key={idx} p={2} bg="gray.50" borderRadius="md" boxShadow="sm" spacing={3}>
                  <Icon as={skill.icon} w={5} h={5} color="blue.500" />
                  <VStack align="start" spacing={0} flex="1">
                    <Text fontSize="sm" fontWeight="bold" color="gray.800">
                      {skill.name}
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      Score: {skill.score}%
                    </Text>
                    <Progress value={skill.score} size="xs" colorScheme="blue" />
                  </VStack>
                </HStack>
              ))}
            </SimpleGrid>
          </Box>
        ))}
        <Button colorScheme="teal" size="sm" mt={2} alignSelf="center">
          Generate Training Plan
        </Button>
      </VStack>
    </Box>
  );
};

export default CallSkillsScoreCard;