import React from 'react';
import { Box, Button, Flex, Text, Avatar, Badge } from '@chakra-ui/react';

const assistants = [
  {
    name: 'B2B Cold Call Assistant',
    role: 'B2B Solutions',
    company: 'TechCorp Inc.',
    opportunityRate: '20.5%',
    description: 'Engage in a conversation about improving operational efficiency.',
  },
  {
    name: 'Demo Assistant',
    role: 'Product Demos',
    company: 'DemoCorp Inc.',
    opportunityRate: '15.0%',
    description: 'Showcase product features and benefits effectively.',
  },
  {
    name: 'Negotiation Assistant',
    role: 'Negotiation Expert',
    company: 'Negotiate Inc.',
    opportunityRate: '25.0%',
    description: 'Assist in closing deals with strategic negotiation tactics.',
  },
  {
    name: 'Security Compliance Assistant',
    role: 'Security Compliance',
    company: 'SecureTech Inc.',
    opportunityRate: '18.0%',
    description: 'Ensure compliance with security standards and protocols.',
  },
  {
    name: 'IT Manager',
    role: 'IT Management',
    company: 'IT Solutions Inc.',
    opportunityRate: '22.0%',
    description: 'Manage IT infrastructure and operations efficiently.',
  },
  {
    name: 'Head of HR',
    role: 'Human Resources',
    company: 'HR Corp Inc.',
    opportunityRate: '19.0%',
    description: 'Lead HR initiatives and improve employee engagement.',
  },
];

interface AssistantProps {
    assistant: any;
  }

const AssistantCard : React.FC<AssistantProps> = ({ assistant }) => (
  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="4" mb="4">
    <Flex align="center">
      <Avatar size="md" name={assistant.name} mr="4" />
      <Box>
        <Text fontWeight="bold">{assistant.name}</Text>
        <Text fontSize="sm">{assistant.role} @ {assistant.company}</Text>
        <Badge colorScheme="green" mt="2">{assistant.opportunityRate}</Badge>
      </Box>
    </Flex>
    <Text mt="4">{assistant.description}</Text>
    <Button colorScheme="teal" mt="4">Start Simulation Call</Button>
  </Box>
);

const AssistantList = () => (
  <Box p="6">
    {assistants.map((assistant, index) => (
      <AssistantCard key={index} assistant={assistant} />
    ))}
    <Button colorScheme="blue" mt="4">Create Personalized Assistant</Button>
  </Box>
);

export default AssistantList;