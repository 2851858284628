import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, sendSignInLinkToEmail, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { OAuthProvider } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBoneutdzLkjHnJrsUj0026RSvJdx3L3G4",
  authDomain: "objectionguru.firebaseapp.com",
  projectId: "objectionguru",
  storageBucket: "objectionguru.appspot.com",
  messagingSenderId: "513827718731",
  appId: "1:513827718731:web:3255a639a612df8e732fce",
  measurementId: "G-Y3T89FDFGV"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  
  const signInWithGoogle = async (toast) => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      toast({
        title: 'Success',
        description: 'Signed in with Google',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error signing in with Google',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const signInWithEmailLink = async (email, toast) => {
    try {
      const getRedirectUrl = () => {
        const { protocol, hostname, port } = window.location;
        
        // For production
        if (hostname !== 'localhost') {
          return `${protocol}//${hostname}/login`;
        }
        
        // For local development
        return `${protocol}//${hostname}:${port}/login`;
      };
  
      const actionCodeSettings = {
        url: getRedirectUrl(), // Dynamically set based on environment
        handleCodeInApp: true,
      };
  
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      toast({
        title: 'Success',
        description: 'Check your email for the sign-in link',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      toast({
        title: 'Error signing in with email',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const signInWithMicrosoft = async (toast) => {
    try {
      const provider = new OAuthProvider('microsoft.com');
      await signInWithPopup(auth, provider);
      toast({
        title: 'Success',
        description: 'Signed in with Microsoft',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error signing in with Microsoft',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  export { auth, db, GoogleAuthProvider, signInWithGoogle, signInWithEmailLink, signInWithMicrosoft };