import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebaseConfig';
import {ConsolePage} from './pages/ConsolePage';
import LandingPage from './pages/LandingPage';
import Resume from './pages/Resume';
import Layout from './components/Layout';
import AssistantList from './pages/AssistantList';
import Login from './components/Login'
import CallSkillsScore from './components/CallSkillsScore';

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={user ? <Navigate to="/resume" /> : <LandingPage />} />
          <Route path="/resume" element={<Layout user={user}> <Resume user={user} /> </Layout>} />
          <Route path="/assistants" element={<Layout user={user}> <AssistantList/> </Layout> } />
          <Route path="/score" element={<Layout user={user}> <CallSkillsScore/> </Layout> } />
          <Route path="/login" element={<Layout user={user}> <Login/> </Layout> } />
          <Route path="/roleplay/:id" element={<ConsolePage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;