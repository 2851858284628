import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  Input,
  useToast,
  Heading,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FaGoogle, FaEnvelope, FaMicrosoft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const toast = useToast();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const emailForSignIn = window.localStorage.getItem('emailForSignIn');
    
    if (emailForSignIn && isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, emailForSignIn, window.location.href)
        .then(() => {
          window.localStorage.removeItem('emailForSignIn');
          toast({
            title: 'Sign-in Successful',
            description: 'Redirecting to your dashboard...',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate('/resume');
        })
        .catch((error) => {
          toast({
            title: 'Sign-in Failure',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [auth, navigate, toast]);

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      toast({
        title: 'Google Sign-in Successful',
        description: 'You have successfully signed in with Google.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/resume');
    } catch (error: any) {
      toast({
        title: 'Google Sign-in Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEmailSignIn = async () => {
    if (!email) {
      toast({
        title: 'Email Required',
        description: 'Please enter an email address.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await sendSignInLinkToEmail(auth, email, {
        url: window.location.origin + '/login',
        handleCodeInApp: true,
      });
      toast({
        title: 'Success',
        description: 'Check your email for the sign-in link',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      const provider = new OAuthProvider('microsoft.com');
      await signInWithPopup(auth, provider);
      toast({
        title: 'Microsoft Sign-in Successful',
        description: 'You have successfully signed in with Microsoft.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/resume');
    } catch (error: any) {
      toast({
        title: 'Microsoft Sign-in Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" minH="100vh" bgColor="gray.50" p={4}>
      <Box maxW="md" w="full" bg="white" borderColor="gray.200" borderWidth="1px" rounded="xl" boxShadow="lg" p={8} m={4}>
        <VStack spacing={6} align="stretch">
          <Heading size="xl" color="teal.800" textAlign="center">
            AI Real-Time Role-Play Call Simulator
          </Heading>
          <Text fontSize="lg" color="gray.600" textAlign="center">
            Enhance Your Deal-Closing Skills with AI-Driven Real-Life Scenarios
          </Text>
          <Button leftIcon={<FaGoogle />} variant="outline" onClick={handleGoogleSignIn} colorScheme="gray">
            Continue with Google
          </Button>
          <Button leftIcon={<FaMicrosoft />} variant="outline" onClick={handleMicrosoftSignIn} colorScheme="gray">
            Continue with Microsoft
          </Button>
          <Input
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Button
            leftIcon={<FaEnvelope />}
            variant="solid"
            onClick={handleEmailSignIn}
            bg="gray.700"
            color="white"
            _hover={{ bg: "gray.600" }}
          >
            Sign in with Email
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Login;