// src/utils/firebase.utils.js

import { auth, db } from '../firebaseConfig';
import { collection, getDoc, doc, getDocs, getFirestore, setDoc, addDoc, serverTimestamp } from 'firebase/firestore';
const firestore = getFirestore();


export const addLead = async (email) => {
  try {
    const docRef = await addDoc(collection(db, 'leads'), {
      email,
      date: serverTimestamp(),
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding lead: ', error);
    throw new Error('Failed to add lead');
  }
};

/**
 * Fetch the API key for the current user's domain.
 */
export const fetchCurrentUserApiKey = async () => {
  try {
    const user = auth.currentUser;
    if (user && user.email) {
      const domain = user.email.split('@')[1];
      const apiKeyDoc = await getDoc(doc(db, 'apiKeys', domain));
      if (apiKeyDoc.exists()) {
        return apiKeyDoc.data().apiKey || null;
      }
    }
    return null;
  } catch (error) {
    console.error('Error fetching API key:', error);
    return null;
  }
};

export const saveCompanyData = async (domainName, data) => {
  try {
    const companyRef = doc(firestore, 'accounts', domainName);
    
    // Saving data to Firestore with provided domainName and data
    await setDoc(companyRef, data, { merge: true });

    console.log(`Company data for ${domainName} saved successfully.`);
  } catch (error) {
    console.error('Error saving company data:', error);
    throw new Error('Failed to save company data. Please try again later.');
  }
};

export const checkCompanyData = async (domainName) => {
  // Attempt to fetch company data for the email domain
  const companyRef = doc(firestore, 'accounts', domainName);
  const companySnapshot = await getDoc(companyRef);

  if (companySnapshot.exists()) {
    return {
      companyExists: true,
      companyName: companySnapshot.data().company_name,
    };
  } else {
    return { companyExists: false };
  }
};

export const checkApiKey = async (domainName) => {
  // Attempt to fetch API key data for the email domain
  const apiKeyRef = doc(firestore, 'apiKeys', domainName);
  const apiKeySnapshot = await getDoc(apiKeyRef);

  if (apiKeySnapshot.exists()) {
    return {
      apiKeyExists: true,
      apiKey: apiKeySnapshot.data().apiKey,
    };
  } else {
    return { apiKeyExists: false };
  }
};


/**
 * Fetch simulations for the current authenticated user.
 */
export const fetchUserSimulations = async () => {
  try {
    const userUid = auth.currentUser?.uid;
    if (userUid) {
      const simulationsRef = collection(db, `accounts/${userUid}/simulations`);
      const simulationSnap = await getDocs(simulationsRef);
      return simulationSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching simulations:', error);
    return [];
  }
};