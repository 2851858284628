import React, { ReactNode } from 'react';
import { Flex, Box, Heading, Image, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { User } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebaseConfig'; 

interface LayoutProps {
  children: ReactNode;
  user: User | null; 
}

const Layout: React.FC<LayoutProps> = ({ children, user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/'); // Redirect to landing page after logout
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  return (
    <Flex direction="column" minH="100vh" bg="gray.50">
      {/* Header */}
      <Flex w="full" p={4} bg="white" align="center" boxShadow="md" justify="space-between" px={8}>
        <Flex align="center">
          <Image src="/logo.png" alt="Logo" boxSize="40px" />
          <Heading size="md" color="gray.700" ml={3}>
            ObjectionGuru
          </Heading>
        </Flex>
        <Flex align="center">
          {user && user.photoURL ? (
            <Menu>
              <MenuButton as={Box} cursor="pointer">
                <Image
                  borderRadius="full"
                  boxSize="40px"
                  src={user.photoURL}
                  alt="Profile"
                />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          ) :  location.pathname !== '/login' && (
            <Button variant="outline" colorScheme="teal" onClick={() => navigate('/login')}>
              Login
            </Button>
          )}
        </Flex>
      </Flex>

      {/* Main content */}
      <Flex flex="1" w="full" p={8} justify="center">
        {children}
      </Flex>

      {/* Footer */}
      <Flex w="full" p={4} bg="gray.600" align="center" justify="center">
        <Box color="white" textAlign="center">
          &copy; {new Date().getFullYear()} ObjectionGuru. All Rights Reserved.
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;